import styled from 'styled-components'
import _ from 'lodash'

import { white } from '../../config/theme/colors'
import media, { useBreakpoint } from '../../config/theme/media'

import Link from '../Link'

const footerContentSpacing = 20

const FooterWrapper = styled.footer`
  background-color: ${white()};
  width: 100%;
  padding: 20px 0 40px 0;
`

const FooterWrapperMobile = styled(FooterWrapper)`
  display: none;
  ${media.lessThan('md')`
    display: block;
  `}
`

const FooterWrapperDesktop = styled(FooterWrapper)`
  display: block;
  ${media.lessThan('md')`
    display: none;
  `}
`

const FooterContainer = styled.div`
  display: flex;
  flex: 1;
  padding: 0 40px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  overflow-y: visible;
  overflow-x: hidden;

  ${media.lessThan('md')`
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    padding: 0 ${footerContentSpacing}px;
  `}
`

const FooterContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;

  ${media.lessThan('md')`
    align-items: ${(props) => props.alignItems ? props.alignItems : 'flex-start'};
    flex-grow: 1;
    flex-direction: column;
    padding: 0 ${footerContentSpacing}px ${footerContentSpacing}px ${footerContentSpacing}px;
  `}
`

const FooterLogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const FooterLogo = styled.img`
  height: 55px;

  ${media.lessThan('md')`
    max-width: 100%;
    height: auto;
    max-height: 45px;
  `}
`

const FooterLink = styled(Link)`
  margin: 0 5px;
  text-align: center;

  ${media.lessThan('md')`
    line-height: 24px;
    margin: 0;
  `}
  ${media.forEach({
    values: { sm: '12', md: '12', lg: '14' },
    getStyle: (val) => `font-size: ${val}px;`
  })}
`

const Block = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
  margin: 0 -${footerContentSpacing}px 0 -${footerContentSpacing}px;
`

const RowBlock = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 -${footerContentSpacing}px 0 -${footerContentSpacing}px;
`

const Footer = ({
  footerLinks,
  footerLogoUrl,
  footerLogoPrimaryLinkUrl,
  footerLogoSecondaryUrl,
  footerLogoSecondaryLinkUrl
}) => {
  const { lt } = useBreakpoint()

  const linksComponent = (
    <FooterContent>
      {
        _.map(footerLinks, (footerLink) => {
          const fields = _.get(footerLink, 'fields', {})
          const publishOnWebsite = _.get(fields, 'publishOnWebsite')
          const contentTitle = _.get(fields, 'contentTitle')
          const pageUrlName = _.get(fields, 'pageUrlName')

          if (!publishOnWebsite) {
            return null
          }

          return (
            <FooterLink href={`/${pageUrlName}`} key={`FooterLink_${pageUrlName}`}>
              {contentTitle}
            </FooterLink>
          )
        })
      }
    </FooterContent>
  )

  const primaryComponent = (
    <FooterContent alignItems="flex-end">
      {footerLogoUrl && (
        <Link href={footerLogoPrimaryLinkUrl}>
          <FooterLogoContainer>
            <FooterLogo src={footerLogoUrl} alt='Medicines &amp; Healthcare products Regulatory Agency' />
          </FooterLogoContainer>
        </Link>)
      }
    </FooterContent>
  )

  const secondaryComponent = (
    <FooterContent>
      {footerLogoSecondaryUrl && (
        <Link href={footerLogoSecondaryLinkUrl}>
          <FooterLogoContainer>
            <FooterLogo src={footerLogoSecondaryUrl} alt='MHRA' />
          </FooterLogoContainer>
        </Link>
      )}
    </FooterContent>
  )

  return (
    <>
      <FooterWrapperMobile>
        <FooterContainer>
          <Block>
            {linksComponent}
          </Block>
          <RowBlock>
            {secondaryComponent}
            {primaryComponent}
          </RowBlock>
        </FooterContainer>
      </FooterWrapperMobile>
      <FooterWrapperDesktop>
        <FooterContainer>
          {secondaryComponent}
          {linksComponent}
          {primaryComponent}
        </FooterContainer>
      </FooterWrapperDesktop>
    </>
  )
}

export default Footer
