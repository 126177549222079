import styled from 'styled-components'
import _ from 'lodash'
import { SkipNavLink, SkipNavContent } from '@reach/skip-nav'

import { useLayout, useAnnouncement } from '../hooks/resourceHooks'
import { useLogoutTimeout } from '../hooks/authHooks'

import SEO from '../components/Page/SEO'
import Header from '../components/Page/Header'
import AnnouncementBar from '../components/Page/AnnouncementBar'
import Footer from '../components/Page/Footer'
import { PageBlock } from '../components/Blocks'

const StyledPage = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 0;
  margin: 0;
  justify-content: flex-start;
  width: 100%;
  max-width: 100%;
`

const Page = ({ meta = {}, isHomepage, children, pageBlockHeight, logoutTimeout = false, isPrintPage, ...props }) => {
  const layout = useLayout()
  const announcement = useAnnouncement()
  useLogoutTimeout({ shouldLogout: logoutTimeout })
  // SEO
  const { title, description, keywords } = meta
  const homepageTitle = _.get(layout, 'pageTitle')
  const siteTitle = _.get(layout, 'siteTitle')
  const pageMetaDescription = _.get(layout, 'pageMetaDescription')
  const pageMetaKeywords = _.get(layout, 'pageMetaKeywords')

  // Header
  const headerLogoTitle = _.get(layout, 'headerLogo.fields.title')
  const headerLogoUrl = _.get(layout, 'headerLogo.fields.file.url')
  const headerBannerLogoUrl = _.get(layout, 'websiteBannerLogo.fields.file.url')
  const headerBannerText = _.get(layout, 'websiteBannerText')
  const headerBannerLinkUrl = _.get(layout, 'websiteBannerLinkUrl')
  const headerBannerBackgroundColor = _.get(layout, 'websiteBannerBackgroundColor', '#000000')
  const showWebsiteBannerLogo = _.get(layout, 'showWebsiteBannerLogo', true)

  // Announcement
  const contentTitle = _.get(announcement, 'contentTitle')
  const pageDescription = _.get(announcement, 'pageDescription')
  const externalUrl = _.get(announcement, 'externalUrl')
  const publishDateTime = _.get(announcement, 'publishDateTime')
  const unpublishDateTime = _.get(announcement, 'unpublishDateTime')
  const showAnnouncementBar = _.get(announcement, 'showAnnouncementBar')

  // Footer
  const footerLinks = _.get(layout, 'footerLinks')
  const footerLogoUrl = _.get(layout, 'footerLogo.fields.file.url')
  const footerLogoPrimaryLinkUrl = _.get(layout, 'footerLogoPrimaryLinkUrl')
  const footerLogoSecondaryUrl = _.get(layout, 'footerLogoSecondary.fields.file.url')
  const footerLogoSecondaryLinkUrl = _.get(layout, 'footerLogoSecondaryLinkUrl')

  return (
    <StyledPage>
      <SkipNavLink />
      <SEO
        title={title || homepageTitle}
        siteTitle={siteTitle}
        description={description || pageMetaDescription}
        keywords={keywords || pageMetaKeywords}
      />
      <Header
        isHomepage={isHomepage}
        siteTitle={siteTitle}
        headerLogoTitle={headerLogoTitle}
        headerLogoUrl={headerLogoUrl}
        headerBannerLogoUrl={headerBannerLogoUrl}
        headerBannerText={headerBannerText}
        headerBannerLinkUrl={headerBannerLinkUrl}
        headerBannerBackgroundColor={headerBannerBackgroundColor}
        showWebsiteBannerLogo={showWebsiteBannerLogo}
        bannerMessage={{
          showMessage: _.get(layout, 'showBannerMessage', false),
          headline: _.get(layout, 'bannerMessageHeadline'),
          subtitle: _.get(layout, 'bannerMessageSubtitle'),
          linkLabel: _.get(layout, 'bannerMessageLinkLabel'),
          linkUrl: _.get(layout, 'bannerMessageLinkUrl'),
          backgroundColor: _.get(layout, 'bannerMessageBackgroundColour', '#000000')
        }}
        headerSecondaryLogo={{
          showLogo: _.get(layout, 'showHeaderSecondaryLogo', false),
          logoUrl: _.get(layout, 'headerSecondaryLogo.fields.file.url'),
          logoAlt: _.get(layout, 'headerSecondaryLogo.fields.description') || _.get(layout, 'headerSecondaryLogo.fields.title'),
          linkUrl: _.get(layout, 'headerSecondaryLogoLinkUrl')
        }}
        isPrintPage={isPrintPage}
      />
      {!isPrintPage && <AnnouncementBar
        contentTitle={contentTitle}
        pageDescription={pageDescription}
        externalUrl={externalUrl}
        publishDateTime={publishDateTime}
        unpublishDateTime={unpublishDateTime}
        showAnnouncementBar={showAnnouncementBar}
      />}
      <SkipNavContent />
      <main>
        <PageBlock
          pageBlockHeight={pageBlockHeight}
        >
          {children}
        </PageBlock>
      </main>
      {!isPrintPage && <Footer
        footerLinks={footerLinks}
        footerLogoUrl={footerLogoUrl}
        footerLogoPrimaryLinkUrl={footerLogoPrimaryLinkUrl}
        footerLogoSecondaryUrl={footerLogoSecondaryUrl}
        footerLogoSecondaryLinkUrl={footerLogoSecondaryLinkUrl}
      />}
    </StyledPage>
  )
}

export default Page
