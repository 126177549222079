import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import styled from 'styled-components'
import media from '../config/theme/media'
import Link from 'next/link'
import { bodyFontColor } from '../config/theme/colors'

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  text-decoration: none;
  color: ${bodyFontColor};
  font-weight: 700;
  ${media.greaterThan('md')`
    flex-wrap: nowrap;
    padding: 15px 0;
    margin-bottom: 0;
    justify-contents: stretch;
  `}
`

const HeaderItem = styled.div`
  display: flex;
  padding-left: 10px;
  padding-right: 10px;
  align-items: center;
  border-left: 2px solid ${bodyFontColor};
  ${media.lessThan('md')`
    &:last-child {
      margin-top: 5px;
      border-left: 0;
      margin-left: 0;
      padding-left: 0;
      width: ${({ isTitle }) => isTitle ? '100%' : 'auto'};
    }
  `}
  ${media.greaterThan('md')`
    padding-left: 15px;
    padding-right: 15px;
  `}
  &:first-child {
    border-left: none;
    margin-left: 0;
    padding-left: 0;
  }  
`

const Logo = styled.img`
  max-height: 40px;
  max-width: 172px;
  ${media.greaterThan('md')`
    max-height: 50px;
    max-width: 160px;
  `}
`

const SiteTitle = styled.span`
  display: block;
  font-size: 14px;
  ${media.greaterThan('md')`
    font-size: 21px;
  `}
  ${media.greaterThan('lg')`
    font-size: 24px;
  `}
`

const SiteLogo = (props) => {
  const { siteTitle, logoTitle, logoUrl, secondaryLogo = {} } = props
  const {
    showLogo: showSecondaryLogo = false,
    logoUrl: secondaryLogoUrl,
    linkUrl: secondaryLinkUrl = '/',
    logoAlt: secondaryLogoAlt
  } = secondaryLogo
  const withLogo = _.isString(logoTitle) && _.isString(logoUrl)

  return (
    <Container>
      {withLogo && (
        <HeaderItem>
          <Link href='/'>
            <a>
              <Logo src={logoUrl} alt={logoTitle} />
            </a>
          </Link>
        </HeaderItem>
      )}
      {showSecondaryLogo && (
        <HeaderItem>
          <Link href={secondaryLinkUrl}>
            {withLogo && (
              <a target={'_blank'} aria-label='Opens in new window'>
                <Logo src={secondaryLogoUrl} alt={secondaryLogoAlt} />
              </a>
            )}
          </Link>
        </HeaderItem>
      )}
      {siteTitle && (
        <HeaderItem isTitle>
          <SiteTitle>{siteTitle}</SiteTitle>
        </HeaderItem>
      )}
    </Container>
  )
}

SiteLogo.propTypes = {
  logoTitle: PropTypes.string.isRequired,
  logoUrl: PropTypes.string.isRequired,
  siteTitle: PropTypes.string
}

export default SiteLogo
