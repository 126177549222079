import { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import useTranslate from '../../hooks/useTranslate'
import Button from '../Button'
import FollowUpCountBadge from '../FollowUpCountBadge'

const heightIcon = '19px'
const widthLine = '26px'
const heightLine = '3px'
const spanColor = 'black'

const BurgerMenuButton = styled(Button)`
    width: 10px;
    height: ${heightIcon};
    position: relative;
    display: block;
    background-color: transparent;
    box-shadow: none;
    margin-top: 11px;
    
    .line {
      display: block;
      background: ${spanColor};
      width: ${widthLine};
      height: ${heightLine};
      position: absolute;
      left: 0;
      border-radius: calc(${heightLine} / 2);
      
      &.line-1 {
        top: 0;
      }
      &.line-2 {
        top: calc(50% - ${heightLine} / 2);
      }
      &.line-3 {
        top: calc(100% - ${heightLine});
      }
    }

    &:focus, &:hover {
      background-color: transparent;
    }

    &.active {
        height: calc(${heightIcon} + 4px);
        .line-1 {
            top: calc(50% - ${heightLine});
            transform: rotate(45deg);
        }
        .line-2 {
            opacity:0;
        }
        .line-3 {
            top: calc(50% - ${heightLine});
            transform: rotate(-45deg);
          }
    }
`


const BurgerMenu = ({ ...props }) => {
    const { menuActive, onClick, followUpCount } = props
    const translate = useTranslate()

    return (
        <BurgerMenuButton onClick={() => onClick()} className={menuActive ? 'active' : ''}>
            {followUpCount > 0 && !menuActive &&
                <FollowUpCountBadge followUpCount={followUpCount} />
            }
                <span className="line line-1"></span>
                <span className="line line-2"></span>
                <span className="line line-3"></span>
        </BurgerMenuButton>
    )
}

export default BurgerMenu
