import { useSelector } from 'react-redux'

import { getFollowUpsSelector, getFollowUpsCount } from '../store/modules/followUps/selectors'

/**
 * @description returns followups for user to complete
 * @returns
 */
export const useFollowUps = () => {
  return useSelector(getFollowUpsSelector)
}

/**
 * @description returns count of followups for user to complete
 * @returns
 */
export const useFollowUpsCount = () => {
  return useSelector(getFollowUpsCount)
}
