import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useRouter } from 'next/router'
import {
  getIsLoggedIn,
  getCurrentUser,
  getUserInitialFormValues,
  getErrorMessage,
  getUserProfessionType,
  getIsUserAdmin,
  getUserMigrationStatus,
  getKeepMeLoggedInStatus,
  getUserPasswordPolicyIsValid,
  getUserToken,
  getUserDetails,
  getTestUserDetails,
  getTestFormViewId,
  getIsRestored,
  getUserWatchlistProducts,
  getIsLoggedOut
} from '../store/modules/auth/selectors'
import _ from 'lodash'
import moment from 'moment'

/**
 * @description returns if user is currently logged in
 * @returns
 */
export const useIsLoggedIn = () => {
  return useSelector(getIsLoggedIn)
}

/**
 * @description returns if auth data is restored
 * @returns
 */
export const useIsRestored = () => {
  return useSelector(getIsRestored)
}

/**
 * @description returns if logged out
 * @returns
 */
export const useIsLoggedOut = () => {
  return useSelector(getIsLoggedOut)
}

/**
 * @description returns the current user
 * @returns
 */
export const useGetCurrentUser = () => {
  return useSelector(getCurrentUser)
}

/**
* @description returns initial form values based on logged in user
* @returns
*/
export const useUserInitialFormValues = () => {
  return useSelector(getUserInitialFormValues)
}

/**
 * @description returns current error message
 * @returns
 */
export const useErrorMessage = () => {
  return useSelector(getErrorMessage)
}

/**
 * @description returns the profession type of the current user
 * @returns
 */
export const useUserProfessionType = () => {
  return useSelector(getUserProfessionType)
}

/**
 * @description returns the profession type of the current user
 * @returns
 */
export const useUserWatchlistProducts = () => {
  return useSelector(getUserWatchlistProducts)
}

/**
 * @description returns if the current user is an admin
 * @returns
 */
export const useIsUserAdmin = () => {
  return useSelector(getIsUserAdmin)
}

/**
 * @description Gets the migration status of a user
 * This is currently if the password matches the Vigilance Hub's criteria
 * @returns {Object} If user is logged in will return isValid, resetToken and array of errors
 */
export const useGetUserMigrationStatus = () => {
  return useSelector(getUserMigrationStatus)
}

/**
 * @description Should the user be shown the migration screen.
 * @returns {boolean} Returns true if the password is strong enough
 */
export const useShouldShowMigration = () => {
  const isValid = useSelector(getUserPasswordPolicyIsValid)
  return !isValid
}

/**
 * @description User has enabled keep me logged in
 * @returns {boolean}
 */
export const useKeepMeLoggedIn = () => {
  return useSelector(getKeepMeLoggedInStatus)
}

/**
 * @description returns user token if exists
 * @returns {string}
 */
export const useGetUserToken = () => {
  return useSelector(getUserToken)
}

/**
 * @description returns user details
 * @returns {Object}
 */
export const useGetUserDetails = () => {
  return useSelector(getUserDetails)
}

/**
 * @description returns test user details
 * @returns {Object}
 */
export const useTestUserDetails = () => {
  return useSelector(getTestUserDetails)
}

/**
 * @description returns test formViewId
 * @returns {string}
 */
export const useTestFormViewId = () => {
  return useSelector(getTestFormViewId)
}

/**
 * @description log the user out if the timeout has expired
 */
export const useLogoutTimeout = ({ shouldLogout }) => {
  const router = useRouter()
  const isLoggedIn = useIsLoggedIn()
  const keepMeLoggedIn = useKeepMeLoggedIn()
  useEffect(() => {
    if (shouldLogout) {
      if (isLoggedIn && !_.isBoolean(keepMeLoggedIn) && moment(keepMeLoggedIn).isBefore(moment())) {
        router.push({
          pathname: '/logout',
          query: {
            redirectTo: '/sign-in',
            reason: 'SESSION_EXPIRED'
          }
        })
      }
    }
  }, [isLoggedIn, keepMeLoggedIn])
}

/**
 * @description returns true if user is logged in and has a valid token
 * @returns {boolean}
 */
export const useHasAccess = ({ keepMeLoggedIn, isLoggedIn }) => {
  const hasExpired = moment(keepMeLoggedIn).isValid() && moment(keepMeLoggedIn).isBefore(moment())
  const hasAccess = isLoggedIn && (keepMeLoggedIn === true || !hasExpired)
  return hasAccess
}
