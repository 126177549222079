import Head from 'next/head'

import { useWebsiteConfig } from '../../hooks/configHooks'
import { GTMScript } from '../GTM'

const getTitle = (title, siteTitle, siteName) => {
  if (!title) {
    return siteTitle || siteName
  }
  return [title, siteTitle || siteName].join(' | ')
}

const SEO = ({
  title,
  siteTitle,
  description,
  keywords
}) => {
  const websiteConfig = useWebsiteConfig()
  const { gaTrackingId, extSearchEngineIndex = false, gtmId, siteName } = websiteConfig
  return (
    <Head>
      <title>{getTitle(title, siteTitle, siteName)}</title>
      <link rel='icon' href='/favicon.ico' />
      <link rel='apple-touch-icon' sizes='180x180' href='/apple-touch-icon.png' />
      <link rel='icon' type='image/png' sizes='32x32' href='/favicon-32x32.png' />
      <link rel='icon' type='image/png' sizes='16x16' href='/favicon-16x16.png' />
      <link rel='manifest' href='/site.webmanifest' />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta name='msapplication-TileColor' content='#da532c' />
      <meta name='theme-color' content='#ffffff' />
      <meta name='description' content={description} />
      <meta name='keywords' content={keywords} />
      {extSearchEngineIndex ? null : <meta name="robots" content="noindex,nofollow" />}
      {/* Google Analytics */}
      <script async src={`https://www.googletagmanager.com/gtag/js?id=${gaTrackingId}`} />
      <script
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${gaTrackingId}', {
              page_path: window.location.pathname,
            });
          `
        }}
      />
      {gtmId && GTMScript({ gtmId })}
    </Head>
  )
}

export default SEO
