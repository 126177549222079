import React from 'react'
import styled from 'styled-components'
import media from '../../config/theme/media'
import Link from '../Link'

const BannerContainer = styled.div`
  color: white;
  display: flex;
  flex: 1;
  padding: 0 40px;
  align-items: center;
  ${media.lessThan('md')`
    padding: 10px;
  `}
`

const BannerLogo = styled.img`
  max-height: 30px;
  max-width: 100%;
`

const LogoContainer = styled.div``

const Banner = ({
  headerBannerLogoUrl,
  headerBannerText,
  headerBannerLinkUrl
}) => {
  const LogoContainerComponent = headerBannerLinkUrl ? Link : LogoContainer
  const LogoContainerProps = headerBannerLinkUrl
    ? {
      href: headerBannerLinkUrl,
      noDecoration: true,
      target: '_blank'
    }
    : {}

  return (
    <BannerContainer>
      <LogoContainerComponent {...LogoContainerProps}>
        <BannerLogo src={headerBannerLogoUrl} alt={headerBannerText} />
      </LogoContainerComponent>
    </BannerContainer>
  )
}

export default Banner
