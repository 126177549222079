import { useSelector } from 'react-redux'
import {
  getWebsiteConfiguration,
  getAgreeTerms,
  showTermsOfUse,
  getNodeEnv,
  getIsProduction
} from '../store/modules/config/selectors'

/**
 * @description website config
 * @returns {}
 */
export const useWebsiteConfig = () => {
  return useSelector(getWebsiteConfiguration)
}

/**
 * @description user has agreed to terms of use
 * @returns {}
 */
export const useAgreeTerms = () => {
  return useSelector(getAgreeTerms)
}

/**
 * @description should display terms of use on register
 * @returns {}
 */
export const useShowTerms = () => {
  return useSelector(showTermsOfUse)
}

/**
 * @description get the current environment
 * @returns {string}
 */
export const useEnv = () => {
  return useSelector(getNodeEnv)
}

/**
 * @description get whether the current environment is set to production
 * @returns {boolean}
 */
export const useIsProduction = () => {
  return useSelector(getIsProduction)
}
