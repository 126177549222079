import { useSelector } from 'react-redux'
import { useRouter } from 'next/router'
import _ from 'lodash'
import moment from 'moment'

import {
  resourceTypeSelector,
  requestSelector
} from '../store/modules/resources/selectors'
import { getFirstResource, getFields, getHasFields, getResources } from '../utils/resources'

/**
 * @description returns all resources of resourceType
 * @param {*} { resourceType }
 * @returns
 */
export const useResourceType = ({ resourceType }) => {
  return useSelector(resourceTypeSelector(resourceType))
}

/**
 * @description returns all status & data of a request to contentful
 * @param {*} { resourceType, requestKey }
 * @returns
 */
export const useRequest = ({ resourceType, requestKey }) => {
  return useSelector(requestSelector(resourceType, requestKey))
}

/**
 * @description returns Page resource fields
 * @param pathname pathname of page resource to fetch
 * @returns {*}
 */
export const usePage = (pathname, previews) => {
  const resource = getFirstResource(
    previews || useRequest({ resourceType: 'page', requestKey: pathname })
  )
  return getFields(resource)
}

/**
 * @description returns layout resource fields
 * @returns {*}
 */
export const useLayout = () => {
  const request = useRequest({ resourceType: 'layout' })
  const resource = getFirstResource(request)
  return getFields(resource)
}

/**
 * @description returns dashboard resource fields
 * @returns {*}
 */
export const useDashboard = () => {
  const request = useRequest({ resourceType: 'dashboard' })
  const resource = getFirstResource(request)
  return getFields(resource)
}

/**
 * @description returns watchlist product resource fields
 * @returns {*}
 */
 export const useWatchlistProduct = () => {
  const request = useRequest({ resourceType: 'watchlistProduct' })
  const resource = getFirstResource(request)
  return getFields(resource)
}

/**
 * @description returns report main page resource fields
 * @returns {*}
 */
 export const useReportMainPage = () => {
  const request = useRequest({ resourceType: 'reportMainPage' })
  const resource = getFirstResource(request)
  return getFields(resource)
}

/**
 * @description returns announcement resource fields
 * @returns {*}
 */
export const useAnnouncement = () => {
  const router = useRouter()
  const request = useRequest({ resourceType: 'announcement' })
  const resource = getFirstResource(request)
  const fields = getFields(resource)

  const contentTitle = _.get(fields, 'contentTitle')
  const publishDateTime = _.get(fields, 'publishDateTime')
  const unpublishDateTime = _.get(fields, 'unpublishDateTime')
  const isPublished = moment(publishDateTime).isBefore(moment())
  const isUnpublished = moment(unpublishDateTime).isBefore(moment())
  const showAnnouncement = contentTitle && isPublished && !isUnpublished
  const showAnnouncementBar = showAnnouncement && router.pathname !== '/announcement'

  return {
    ...fields,
    showAnnouncement,
    showAnnouncementBar
  }
}

/**
 * @description returns drugReportTrigger resources
 * @returns {*}
 */
export const useDrugReportTriggers = () => {
  const request = useRequest({ resourceType: 'drugReportTrigger' })

  const resources = getResources(request)
  return getFields(resources)
}

/**
 * @description returns FAQCategory resources
 * @returns {*}
 */
export const useFaqCategory = () => {
  const request = useRequest({ resourceType: 'faqCategory' })
  const resources = getResources(request)
  const categories = getFields(resources)
  const categoryFaqs = _.map(categories, category => {
    const faqFields = _.get(category, 'faq')
    return {...category, ...getHasFields(faqFields) && {faq: getFields(faqFields)} }
  })
  return categoryFaqs
}

/**
 * @description returns search results resource
 * @param {*} { resourceType }
 * @returns {*}
 */
export const useSearchResults = ({ resourceType }) => {
  const request = useRequest({ resourceType })
  const resources = getResources(request)
  return getFields(resources)
}

/**
 * @description returns resource categories from contentful
 * @returns {*}
 */
export const useResourceCategories = () => {
  const request = useRequest({ resourceType: 'resourcesCategory' })
  const resources = getResources(request)
  const categories = getFields(resources)
  return categories
}

/**
 * @description returns all `resources` content type from contentful
 * @returns {*}
 */
export const useResources = () => {
  const request = useRequest({ resourceType: 'resources' })
  const resources = getResources(request)
  const fields = getFields(resources)
  return fields
}
