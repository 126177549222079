import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import _ from 'lodash'
import { useRouter } from 'next/router'
import { useDispatch } from 'react-redux'
import VisuallyHidden from '@reach/visually-hidden'
import * as colors from '../../config/theme/colors'
import { useLayout } from '../../hooks/resourceHooks'

import media, { useBreakpoint } from '../../config/theme/media'

import { actions as followUpsActions } from '../../store/modules/followUps'

import useTranslate from '../../hooks/useTranslate'
import { useIsLoggedIn, useGetCurrentUser } from '../../hooks/authHooks'
import { useFollowUpsCount } from '../../hooks/followUpsHooks'
import { useWebsiteConfig } from '../../hooks/configHooks'

import Span from '../Typography/Span'
import Link from '../Link'
import Button from '../Button'
import SiteLogo from '../SiteLogo'
import Search from 'components/Search'
import Banner from './Banner'
import BannerMessage from './BannerMessage'
import BurgerMenu from './BurgerMenu'
import FollowUpCountBadge from '../FollowUpCountBadge'

const headerBreakpoint = '880px'

const HeaderWrapper = styled.header`
  background-color: ${(props) => props.background};
  width: 100%;

  ${media.greaterThan('md')`
    padding: ${(props) => props.paddingY} 0px;
  `}
`

const HeaderContainer = styled.div`
  padding: 0 28px;
  position: relative;
  
  ${media.greaterThan(headerBreakpoint)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  `}

  ${media.lessThan('md')`
    padding: 0;
    align-items: start;
    flex-direction: column;
    `}
`

const HeaderLogo = styled.div`
  display: inline-block;
  width: 60%;

  ${media.lessThan(headerBreakpoint)`
      width: calc(100% - 20px);
  `}

  ${media.lessThan('md')`
    padding: 10px;
  `}
`

const HeaderContent = styled.div`
  display: flex;
  justify-content: end;
  padding: 20px 0;
  
  ${media.lessThan(headerBreakpoint)`
    padding-bottom: 18px;
    padding-top: 0;
    justify-content: start;
  `}

  ${media.lessThan('md')`  
    background-color: #f7f7f7;
    border-bottom: ${props => props.hasNav ? '0' : '1px solid #e7e7e7'};
    flex-direction: column;
    padding-bottom: 0;
    align-items: normal;
  `}
`

const NavContainer = styled.div`
  flex: 1 100%;
  padding: 0;
  background-color: ${colors.navBgColor};
  border-bottom: 1px solid #e7e7e7;
  ${media.greaterThan('md')`
    padding: 0 28px;
  `}
`

const Nav = styled.nav`
  display: flex;
  align-items: left;
  justify-content: left;
  flex-direction: row;
  width: 100%;
  
`

const NavList = styled.ul`
  list-style: none;
  position: relative;
  margin: 0;
  padding: 0;
`

const NavListItem = styled.li`
  box-sizing: border-box;
  display: block;
  position: relative;
  height: 50px;
  padding: 0 15px;
  line-height: 50px;
  float: left;

  &.current {
    border-bottom: 4px solid ${colors.hyperlink()};

    a:hover {
      border-bottom: 0;
    }
  }
  
  ${media.lessThan('md')`
    float: none;
    display: block;
    padding-left: 4px;

    &.current {
      border-bottom: 0px ;
      padding-left: 0;
      border-left: 4px solid ${colors.hyperlink()};
    }  
  `}
`

const NavItemLink = styled(Link)`
  font-weight: bold;
  text-decoration: none;
  font-size: 18px;

  &:hover {
    color: ${colors.hyperlink()};
    border-bottom: 3px solid;
  }

  &:active {
    background-color: ${colors.primary()};
    color: black;
    border-bottom: 3px solid;
  }

  ${media.lessThan('md')`
    margin: 10px;

    &:hover {
      border-bottom: 0;
    }
  `}
`

const BurgerMenuContainer = styled.div`
  ${media.greaterThan('md')`
    display: none;
  `}
  position: absolute;
  right: 5px;
  top: 7px;
`

const SinginButtonContainer = styled.div`
  ${media.lessThan('md')`  
    display: block;
    width: 100%;

    a {
      width: 100%;
    }
  `}
`

const LoginContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  ${media.lessThan('md')`
    flex-direction: column;
  `}
`

const LoginHelpText = styled(Span)`
  margin-right: 10px;
  text-align: center;
  overflow: hidden;
  color: ${(props) => props.color};
  
  ${media.lessThan('md')`
  margin-left: 10px;
  flex-direction: row;
  margin-bottom: 0;
  margin-top: 10px;
   `}

  ${media.between('600px', '1000px')`
  display: none;
    `}
`

const AccountButton = styled(Button)`
  padding: 0 25px;
  margin-right: 10px;

  ${(props) => props.isShowName ? `
    span {
      font-size: 13px;
      flex-direction: column;
      align-items: center;
    }
  ` : ''}

  ${media.lessThan('md')`
  margin: 10px; 
  border: 1px solid rgb(151, 151, 151);
  
  &:hover {
    background-color: transparent;
  }
  `}
`
const SearchForm = styled.form`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  & button {
    padding: 0 10px;
  }
  &.menuActive {
    border: 1px solid rgb(151, 151, 151);
    ${media.lessThan('md')`
      margin: 0 10px 10px;
    `}
  }
  ${media.greaterThan('md')`
   width: 150px;
  `}
  ${media.greaterThan('lg')`
    width: 246px;
   `}
`

const Name = styled.span`
  display: block;
  font-size: 12px;
  font-weight: normal;
  max-width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${media.lessThan('md')`
    font-size: 11px;
  `}
`

const Header = ({
  siteTitle,
  headerLogoTitle,
  headerLogoUrl,
  headerBannerLogoUrl,
  headerBannerText,
  headerBannerLinkUrl,
  headerBannerBackgroundColor,
  showWebsiteBannerLogo,
  bannerMessage = {},
  headerSecondaryLogo = {},
  isPrintPage
}) => {
  const translate = useTranslate()
  const dispatch = useDispatch()
  const isLoggedIn = useIsLoggedIn()
  const followUpCount = useFollowUpsCount()
  const websiteConfig = useWebsiteConfig()
  const user = useGetCurrentUser()
  const showName = _.get(websiteConfig, 'myAccount.showNameInButton', false)
  const userFirstName = _.get(user, 'firstName')

  const pagePath = process.browser && window.location.pathname
  const layout = useLayout()
  const mainNavigation = _.get(layout, 'mainNavigation.fields.pages')
  const mainNavigationLinks = (prefix = 'desktop') => {
    return _.map(mainNavigation, (link) => {
      const fields = _.get(link, 'fields', {})
      const fieldId = _.get(link, 'sys.id')
      const publishOnWebsite = _.get(fields, 'publishOnWebsite')
      const contentTitle = _.get(fields, 'contentTitle') || _.get(fields, 'name')
      const pageUrlName = _.get(fields, 'pageUrlName')
      const externalUrl = _.get(fields, 'url')
      const newWindow = _.get(fields, 'newWindow')

      if (!externalUrl && !publishOnWebsite) {
        return null
      }

      return (
        <NavListItem key={`${prefix}_${fieldId}`} className={_.toLower(pagePath) === `/${_.toLower(pageUrlName)}` ? 'current' : ''}>
          <NavItemLink
            href={externalUrl || `/${pageUrlName}`}
            key={`MainNavigation_${pageUrlName}`}
            target={newWindow ? '_blank' : ''}
          >
            {contentTitle}
          </NavItemLink>
        </NavListItem>
      )
    }
  )}

  const router = useRouter()
  const [searchValue, setSearchValue] = useState('')

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(followUpsActions.getUserFollowUpCount())
    }
  }, [isLoggedIn])

  const [menuActive, toggleMenu] = useState(false)
  const handleMenuClick = () => {
    toggleMenu(!menuActive)
  }

  const handleChange = (e) => {
    const value = e.target.value
    setSearchValue(value)
  }
  const handleSubmit = (e) => {
    e.preventDefault()
    e.stopPropagation()
    const pathname = _.get(router, 'pathname')
    const queries = _.get(router, 'query')
    const pageTab = _.get(queries, 'tab')

    if (pathname === '/search') {
      setSearchValue('')
    }
    router.push({
      pathname: '/search',
      query: {
        search: searchValue,
        ...(pageTab && { tab: pageTab })
      }
    })
  }

  const { lt } = useBreakpoint()
  const isDesktop = !lt('md')

  const {
    headline: bannerMessageHeadline,
    subtitle: bannerMessageSubtitle,
    linkLabel: bannerMessageLinkLabel,
    linkUrl: bannerMessageLinkUrl,
    showMessage: showBannerMessage,
    backgroundColor: bannerMessageBackgroundColour
  } = bannerMessage

  return (
    <>
      {showWebsiteBannerLogo &&
        <HeaderWrapper
          background={headerBannerBackgroundColor}
          paddingY={'26px'}
        >
          <Banner
            headerBannerLogoUrl={headerBannerLogoUrl}
            headerBannerText={headerBannerText}
            headerBannerLinkUrl={headerBannerLinkUrl}
          />
        </HeaderWrapper>
      }
      {showBannerMessage && (
        <HeaderWrapper background={bannerMessageBackgroundColour}>
          <BannerMessage
            headline={bannerMessageHeadline}
            subtitle={bannerMessageSubtitle}
            linkLabel={bannerMessageLinkLabel}
            linkUrl={bannerMessageLinkUrl}
          />
        </HeaderWrapper>
      )}
      <HeaderWrapper
        background={process.env.headerBackgroundColor}
        color={process.env.headerContrastColor}
      >
        <HeaderContainer>
          {!isPrintPage && <BurgerMenuContainer>
            <BurgerMenu onClick={handleMenuClick} menuActive={menuActive} followUpCount={followUpCount} />
          </BurgerMenuContainer>}
          <HeaderLogo>
            <SiteLogo
              logoUrl={headerLogoUrl}
              logoTitle={headerLogoTitle}
              siteTitle={siteTitle}
              secondaryLogo={headerSecondaryLogo}
            />
          </HeaderLogo>
          {!isPrintPage && (menuActive || isDesktop) && <HeaderContent hasNav={_.size(mainNavigationLinks('mobile')) > 0}>
            <SinginButtonContainer>
              {!isLoggedIn ? (
                <>
                  <LoginContainer>
                    {process.env.showLoginHelpText === true && (
                      <LoginHelpText>
                        {translate('HEADER_LOGIN_HELP_TEXT')}
                      </LoginHelpText>
                    )}
                    <Link href="/sign-in" noDecoration>
                      <AccountButton asComponent={'span'} inverse>
                        {translate('LOGIN')}
                      </AccountButton>
                    </Link>
                  </LoginContainer>
                </>
              ) : (
                <div style={{ position: 'relative' }}>
                  <Link href="/account" noDecoration>
                    <AccountButton asComponent={'span'} inverse isShowName={showName && userFirstName}>
                      {(showName && userFirstName) && <Name>
                        {translate('MY_ACCOUNT_BUTTON_NAME', {firstName: userFirstName})}
                      </Name>}
                      {translate('MY_ACCOUNT')}
                    </AccountButton>
                    {followUpCount > 0 && (
                      <FollowUpCountBadge followUpCount={followUpCount} />
                    )}
                  </Link>
                </div>
              )}
            </SinginButtonContainer>
            <SearchForm onSubmit={(e) => handleSubmit(e)} className={menuActive ? 'menuActive' : ''}>
              <Search
                onChange={handleChange}
                value={searchValue}
                placeholder={translate('HEADER_SEARCH_PLACEHOLDER')}
                inputHeight={30}
                noBorder
              />
            </SearchForm>
          </HeaderContent>}

        </HeaderContainer>
        {!isPrintPage && _.size(mainNavigationLinks('desktop')) > 0 && (menuActive || isDesktop) && <NavContainer >
          <Nav>
            <NavList>
              {mainNavigationLinks('desktop')}
            </NavList>
          </Nav>
        </NavContainer>}
      </HeaderWrapper>
    </>
  )
}

export default Header
