import styled from 'styled-components'
import moment from 'moment'

import { white, black } from '../../config/theme/colors'
import media from '../../config/theme/media'

import Intro from '../Typography/Intro'
import Link from '../Link'

const AnnouncementWrapper = styled.div`
  background-color: ${black()};
  color: ${white()};
  display: flex;
  width: 100%;
  height: 60px;
  align-items: center;
  justify-content: center;

  ${media.lessThan('md')`
    height: auto;
  `}
`
const AnnouncementContainer = styled.div`
  display: flex;
  flex: 1;
  padding: 0 20px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;

  ${media.lessThan('md')`
    padding: 10px 20px;
  `}
`

const Announcement = ({
  contentTitle,
  pageDescription,
  externalUrl,
  publishDateTime,
  unpublishDateTime,
  showAnnouncementBar
}) => {
  if (!showAnnouncementBar) {
    return null
  }

  const href = pageDescription ? '/announcement' : externalUrl
  const noLink = !pageDescription && !externalUrl

  return (
    <AnnouncementWrapper>
      <AnnouncementContainer>
        {
          noLink
            ? (
              <Intro inverse small>{contentTitle}</Intro>
            )
            : (
              <Link href={href} color={white()} target={'_blank'}>
                <Intro inverse small>{contentTitle}</Intro>
              </Link>
            )
        }
      </AnnouncementContainer>
    </AnnouncementWrapper>
  )
}

export default Announcement
