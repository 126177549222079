import React from 'react'
import styled from 'styled-components'
import NextLink from 'next/link'
import media from '../../config/theme/media'

const Container = styled.div`
  color: #ffffff;
  padding: 10px;
  ${media.greaterThan('md')`
    padding: 16px 28px;
  `}
`
const Content = styled.div`
  font-size: 14px;
  line-height: 1.4;
  ${media.greaterThan('md')`
    font-size: 16px;
  `}
  p {
    margin: 0;
    margin-bottom: 10px;
  }
  p:last-child {
    margin-bottom: 0;
  }
`

const Headline = styled.h2`
  font-size: 16px;
  font-weight: 700;
  margin: 0;
  padding: 0;
  ${media.greaterThan('md')`
    font-size: 20px;
  `}
`
const Subtitle = styled.p``
const Link = styled.p`
  a {
    color: #ffffff;
    &:hover, &:active, &:focus {
      text-decoration: none;
    }
  }
`

const BannerMessage = (props) => {
  const {
    headline,
    subtitle,
    linkLabel,
    linkUrl
  } = props

  const hasLink = linkLabel && linkUrl

  return (
    <Container>
      <Content>
        {headline && <Headline>{headline}</Headline>}
        {subtitle && <Subtitle>{subtitle}</Subtitle>}
        {hasLink && (
          <Link>
            <NextLink href={linkUrl}>
              {linkLabel}
            </NextLink>
          </Link>
        )}
      </Content>
    </Container>
  )
}

export default BannerMessage
