import React, { useEffect } from 'react'
import styled from 'styled-components'
import useTranslate from '../hooks/useTranslate'
import VisuallyHidden from '@reach/visually-hidden'

const FollowUpCountIcon = styled.span`
  color: white;
  font-size: 14px;
  background: black;
  border-radius: 50%;
  position: absolute;
  right: 0;
  top: -10px;
  width: 22px;
  height: 22px;
  text-align: center;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`

const FollowUpCountBadge = ({
  followUpCount
}) => {

  const translate = useTranslate()

  return (
    <FollowUpCountIcon>
      {followUpCount}
      <VisuallyHidden>
          {translate('NEW_FOLLOWUPS')}
      </VisuallyHidden>
    </FollowUpCountIcon>
  )
}

export default FollowUpCountBadge
