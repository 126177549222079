import styled from 'styled-components'
import PropTypes from 'prop-types'
import _ from 'lodash'
import VisuallyHidden from '@reach/visually-hidden'

import Icon from './Icon'
import Button from './Button'
import media from 'config/theme/media'
import * as colors from '../config/theme/colors'

const Container = styled.div`
  display: flex;
  flex: 1;
`
const Input = styled.input`
  height: 40px;
  line-height: 40px;
  padding: 0 0 0 10px;
  font-size: 16px;
  width: 100%;
  border: ${(props) =>
    props.noBorder ? 'none' : `1px solid ${colors.searchBarBorderColor}`};
  ${media.lessThan('md')`
    font-size: 18px;
`}
`
const Submit = styled(Button)`
  height: auto;
  display: flex;
  justifycontent: center;
  alignitems: center;
  padding: 0 10px;
  ${media.greaterThan('md')`
    padding: 0 20px;
  `}
`

const Search = (props) => {
  return (
    <Container>
      <Input {...props} />
      <Submit bold large type={'submit'}>
        {<Icon name="search" size={25} />}
        <VisuallyHidden>Search</VisuallyHidden>
      </Submit>
    </Container>
  )
}

Search.propTypes = {
  inputHeight: PropTypes.number,
  noBorder: PropTypes.bool,
}

export default Search
